@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.splide {
  outline: none;
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  transform-style: preserve-3d;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  outline: none;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.splide--draggable > .splide__slider > .splide__track,
.splide--draggable > .splide__track {
  user-select: none;
}

.splide--fade > .splide__slider > .splide__track > .splide__list,
.splide--fade > .splide__track > .splide__list {
  display: block;
}

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide,
.splide--fade > .splide__track > .splide__list > .splide__slide {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active,
.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  opacity: 1;
  position: relative;
  z-index: 1;
}

/* Splide Default Style Files */
@forward '../../template/default';
.type-wviersamera-packageslider,
.type-wvierteaser-teaserslider,
.type-news-pi1,
.frame-type-image,
.type-wvierteaser-teaserslider2 {
  overflow: hidden;
}

.type-wviersamera-packageslider .splide__track,
.type-wvierteaser-teaserslider .splide__track,
.type-news-pi1 .splide__track,
.frame-type-image .splide__track,
.type-wvierteaser-teaserslider2 .splide__track {
  overflow: unset;
}

.splide figure.image img {
  margin-top: 100px;
}

.splide__arrows {
  margin-right: 12px;
}

@media (min-width: 992px) {
  .splide__arrows {
    margin-right: 30px;
  }
}

.splide__arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border: 0;
  padding: 0;
  background: transparent;
  transition: fill 0.4s ease-in-out;
  color: #000;
}

@media (min-width: 992px) {
  .splide__arrow {
    font-size: 26px;
  }
}
