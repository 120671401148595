@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//Customize colors
@import "colors";

// Custom Mixins
@import "mixins";

// Custom Stuff

// Splide Core Files
@import '@splidejs/splide/src/css/core/foundation/animations';

@import '@splidejs/splide/src/css/core/object/objects/root';
@import '@splidejs/splide/src/css/core/object/objects/container';
@import '@splidejs/splide/src/css/core/object/objects/list';
@import '@splidejs/splide/src/css/core/object/objects/pagination';
@import '@splidejs/splide/src/css/core/object/objects/slide';
@import '@splidejs/splide/src/css/core/object/objects/slider';
//@import '@splidejs/splide/src/css/core/object/objects/spinner';
@import '@splidejs/splide/src/css/core/object/objects/track';

@import '@splidejs/splide/src/css/core/object/modifiers/draggable';
@import '@splidejs/splide/src/css/core/object/modifiers/fade';
//@import '@splidejs/splide/src/css/core/object/modifiers/rtl';
//@import '@splidejs/splide/src/css/core/object/modifiers/ttb';

/* Splide Default Style Files */
@import '@splidejs/splide/src/css/themes/default/index';

//
// Custom Splide Styles
//

.type-wviersamera-packageslider,
.type-wvierteaser-teaserslider,
.type-news-pi1,
.frame-type-image,
.type-wvierteaser-teaserslider2 {
    overflow: hidden;
    .splide {
        &__track {
            overflow: unset;

        }
    }
}

.splide {

    figure.image img {
        margin-top: 100px;
    }

    &__arrows {
        margin-right: $grid-gutter-width;

        @include media-breakpoint-up(lg) {
            margin-right: $grid-gutter-width-lg;
        }
    }

    &__arrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        border: 0;
        padding: 0;
        background: transparent;
        transition: fill 0.4s ease-in-out;
        color: $body-color;

        @include media-breakpoint-up(lg) {
            font-size: 26px;
        }
    }
}
